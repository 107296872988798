<template>
  <va-inner-loading :loading="loading" icon="restart_alt">
    <div class="container mx-2">
      <!-- Breadcrumb  -->
      <va-card>
        <va-card-content>
          <div style="display: flex">
            <va-breadcrumbs color="primary">
              <va-breadcrumbs-item :label="$t('menu.teams')" />
            </va-breadcrumbs>
          </div>
        </va-card-content>
      </va-card>
      <div class="mt-3">
        <div class="flex">
          <va-card stripe stripe-color="#1f1f61">
            <va-card-content>
              <h1
                v-if="teams.length == 0"
                class="text-center"
                style="font-size: 32px"
              >
                {{ $t("no_teams") }}
                <va-icon class="material-icons" size="32px"> schedule </va-icon>
              </h1>
              <va-list v-else>
                <va-list-item
                  v-for="(team, index) in teams"
                  :key="team.name"
                  :to="{ name:'team',params:{id:team.id} }"
                  class="flex justify--space-around"
                >
                  <strong>{{ index + offset + 1 }}</strong>
                  <va-list-item-section avatar class="px-3">
                    <va-avatar
                      style="overflow: hidden !important"
                      size="large"
                      font-size="24px"
                    >
                      <img
                        v-if="team.logo"
                        :src="$store.state.imgUrl + team.logo"
                      />
                      <div v-else>{{ team.name.substring(0, 1) }}</div>
                    </va-avatar>
                  </va-list-item-section>

                  <va-list-item-section>
                    <va-list-item-label>
                      <strong>{{ team.name }}</strong>
                    </va-list-item-label>

                    <va-list-item-label
                      v-if="team.city && team.area"
                      caption
                      :lines="index + 1"
                    >
                      {{ `${team.city} , ${team.area}` }}
                    </va-list-item-label>

                    <!--<va-list-item-label caption :lines="index + 1">
                    {{ team.email }}
                    </va-list-item-label> -->
                  </va-list-item-section>

                  <va-list-item-section icon>
                    <va-icon name="remove_red_eye" color="#0a4695" />
                  </va-list-item-section>
                </va-list-item>
              </va-list>
            </va-card-content>
          </va-card>
          <va-pagination
            v-if="pages > 1"
            color="#0a4695"
            style="direction: ltr"
            class="mt-5"
            v-model="currentPage"
            :visible-pages="3"
            :pages="pages"
          />
        </div>
      </div>
    </div>
  </va-inner-loading>
</template>

<script>
import { request,gql } from 'graphql-request';
export default {
    data() {
        return {
            teams: [],
            teamsCount: null,
            pages:null,
            currentPage:1,
            limit:10,
            offset:0,
            loading:true
        }
    },
    async mounted() {
        // Pagination Logic
        try {
        const TEAMS_COUNT = gql`
            query{
                TeamsCount{
                count
                }
            }`
        const counterResponse = await request(this.$store.state.appUrl,TEAMS_COUNT)
        this.teamsCount = counterResponse.TeamsCount.count
        this.pages = Math.ceil(this.teamsCount/this.limit)
        this.getTeams()
        this.loading = false
        } catch (error) {
            console.log(error);
            this.loading= false
            this.$swal.fire({
                    icon: 'warning',
                    title: this.$t('errors.network'),
                    text: 'Try Again in a minute',
                    confirmButtonColor:"#023b81",
                    confirmButtonText:"Reload"
                }).then((result)=>{
                    if(result.value=true){
                    location.reload()
                    }
                })
        }
    },
    methods: {
        async getTeams(){
            this.loading=true
            this.offset = (this.currentPage-1) * this.limit
            const GET_ALL_TEAMS = gql`
                query($offset: Int , $limit : Int) {
                    Teams(limit: $limit , offset:$offset){
                        id
                        name,
                        logo,
                        city,
                        area,
                        manager_id,
                        coach_id
                    }
                }`
            try {
                const response = await request(this.$store.state.appUrl,GET_ALL_TEAMS,{"limit":this.limit,"offset":this.offset})
                this.teams = response.Teams;
                this.loading = false
            } catch (error) {
                console.log(error);
                this.loading= false
                this.$swal.fire({
                        icon: 'warning',
                        title: this.$t('errors.network'),
                        text: 'Try Again in a minute',
                        confirmButtonColor:"#023b81",
                        confirmButtonText:"Reload"
                        }).then((result)=>{
                            if(result.value=true){
                                location.reload()
                            }
                        })
            }

        },
    },
    watch:{
    async currentPage(){
      await this.getTeams()
    }
  }
}
</script>
